/**
 * @file
 * Bootstrap Modal behaviors.
 */
(function ($) {
  // Attach modals to body.
  Drupal.behaviors.modalPosition = {
    attach: function (context, settings) {
      $('.modal', context).once('modal-attach', function () {
        $('.modal').appendTo("body");
      });
    }
  };
})(jQuery);